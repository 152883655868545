import React from 'react'
import styled from 'styled-components'
import { Button } from '../../../atoms/Buttons/Button'
import { CloseButton } from '../../../atoms/Buttons/CloseButton'
import { formatBRL } from '../../../cash-register/common/formatBRL'
import { AvailableProductButtonProps } from '../../../molecules/AvailableProducts/AvailableProductButton'
import AvailableProductsSection from '../../../molecules/AvailableProducts/AvailableProductsSection'
import { EvaluationSimulation } from '../../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { black, gray } from '../../../ui/utils/_colors'
import AlertBox, { AlertBoxProps } from './AlertBox/AlertBox'
import TransactionalCreditProgressBar from './ProgressBar/TransactionalCreditProgressBar'
import InstallmentsSimulationTable from './SimulationTable/InstallmentsSimulationTable'

interface Limit {
  title: string
  totalAmount?: number
  recurrenceAvailableAmount?: number
  evaluationMaxAmount?: number
}

interface FooterButton {
  text: string
  textColor: string
  backgroundColor: string
  border?: string
  onClick?: React.MouseEventHandler<HTMLElement>
}

interface ITransactionalCreditResultGenericScreenProps {
  style: { mainContainerPadding: string }

  icon: React.ReactElement
  title: string
  subtitle: string
  limits?: Limit
  renderProgressBar: boolean
  installmentsSimulation?: EvaluationSimulation[]
  alertBox?: AlertBoxProps

  shouldRenderCloseButton: boolean

  userAvailableProducts?: AvailableProductButtonProps[]
  footerButton?: FooterButton

  onCloseButtonClick?: () => void
}

const TransactionalCreditResultGenericScreen = (props: ITransactionalCreditResultGenericScreenProps) => {
  const { style, icon, title, subtitle, limits, renderProgressBar, installmentsSimulation, alertBox, shouldRenderCloseButton, userAvailableProducts, footerButton, onCloseButtonClick } = props

  return (
    <>
      <MainContainer padding={style.mainContainerPadding}>
        {shouldRenderCloseButton && (
          <CloseButtonContainer>
            <CloseButton onCloseButtonClick={onCloseButtonClick} />
          </CloseButtonContainer>
        )}

        <Header>
          <IconContainer>{icon}</IconContainer>
          <Title>{title}</Title>
          <SubTitle>{subtitle}</SubTitle>
        </Header>

        {limits?.totalAmount && (
          <>
            <Line />
            <LimitContainer>
              <BoldText>{limits.title}</BoldText>
              <Value>{formatBRL(limits.totalAmount)}</Value>
            </LimitContainer>
          </>
        )}

        {renderProgressBar && limits?.recurrenceAvailableAmount && (
          <ProgressBarContainer>
            <TransactionalCreditProgressBar recurrenceAvailableLimit={limits.recurrenceAvailableAmount} evaluationMaxAmount={limits.evaluationMaxAmount} />
          </ProgressBarContainer>
        )}

        {installmentsSimulation && <InstallmentsSimulationTable simulation={installmentsSimulation} />}

        {alertBox && (
          <AlertBoxContainer>
            {(alertBox.evaluationStoreName || alertBox.deniedStatus) && (
              <AlertBox
                evaluationResult={alertBox.evaluationResult}
                evaluationExpirationDate={alertBox.evaluationExpirationDate}
                evaluationStoreName={alertBox.evaluationStoreName}
                deniedStatus={alertBox.deniedStatus}
              />
            )}
          </AlertBoxContainer>
        )}

        {userAvailableProducts && (
          <AvailableProducts>
            <AvailableProductsSection userAvailableProducts={userAvailableProducts} />
          </AvailableProducts>
        )}
      </MainContainer>

      {footerButton && (
        <Footer>
          <Button text={footerButton.text} textColor={footerButton.textColor} backgroundColor={footerButton.backgroundColor} border={footerButton.border} onClick={footerButton.onClick} />
        </Footer>
      )}
    </>
  )
}

export default TransactionalCreditResultGenericScreen

interface MainContainerStyleProps {
  padding: string
}

const MainContainer = styled.div<MainContainerStyleProps>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  padding: ${(props: MainContainerStyleProps) => props.padding};
`

const CloseButtonContainer = styled.div`
  padding-bottom: 16px;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  margin: 16px 0px;
`

const Title = styled.text`
  font-family: Inter;
  font-size: 20px;
  font-weight: bold;
  color: ${black.primary};
  text-align: center;
`

const SubTitle = styled.span`
  font-family: Inter;
  color: ${gray.scale800};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
`

const Line = styled.hr`
  color: ${gray.scale400};
  border-top: 2px solid ${gray.scale400};
  width: 100%;
  margin: 24px 0px;
`

const LimitContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
`

const ProgressBarContainer = styled.div`
  margin-top: 16px;
`

const BoldText = styled.text`
  font-family: Inter;
  font-size: 12px;
  color: ${gray.scale700};
  font-weight: 600;
  line-height: 16px;
  text-align: left;
`

const Value = styled.text`
  font-family: Inter;
  font-size: 24px;
  color: ${black.primary};
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  margin-top: 8px;
`

const AlertBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: 24px 0px;
`

const Footer = styled.div`
  width: 100%;
  padding: 0px 24px 24px 24px;
  flex-direction: column;
  align-items: center;
  bottom: 16px;
`

const AvailableProducts = styled.div`
  height: 100vh;
  margin: 24px -24px 0 -24px;
  background: ${gray.light2};
`
