import React from 'react'
import { ReactComponent as ApprovedCheckIcon } from '../../../../common/assets/images/approved-check-circle.svg'
import { DateFormat, formatDate, TimeZone } from '../../../../common/FormatDate'
import { calculateTxCreditTotalAmount } from '../../../../common/TransactionalCreditHelper'
import { PagesPathEnum } from '../../../../pages/enums/pages-path.enum'
import { AnalyticsEvents } from '../../../../services/analytics/events'
import { AggregatedTransactionalCreditEvaluation } from '../../../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { UserAvailableProducts } from '../../../AvailableProducts/UserAvailableProducts'
import TransactionalCreditResultGenericScreen from '../TransactionalCreditResultGenericScreen.tsx'

interface Props {
  evaluationResult: AggregatedTransactionalCreditEvaluation
  hasCompletedApplication: boolean
  handleGoBackToStart?: () => void
}

export const TransactionalCreditApprovedResult: React.FC<Props> = (props: Props) => {
  const { evaluationResult, hasCompletedApplication, handleGoBackToStart } = props

  const borrowerAvailableLimit = evaluationResult.limit?.availableLimit ?? 0
  const evaluationMaxAmount = evaluationResult.evaluation.policy!!.maxAmount ?? 0

  const totalAmount = calculateTxCreditTotalAmount(evaluationMaxAmount, borrowerAvailableLimit)

  const evaluationExpirationDateFormatted = formatDate(evaluationResult.evaluation.expirationTimestamp, DateFormat.DAY_MONTH_BR_DATE_FORMAT, TimeZone.MANAUS)

  const subtitle = hasCompletedApplication
    ? `Cliente já pode aproveitar o limite extra aprovado nesta loja no caixa até ${evaluationExpirationDateFormatted}.`
    : `Realize o cadastro do cliente e ele já poderá aproveitar o limite extra aprovado nesta loja até o dia ${evaluationExpirationDateFormatted}.`

  const defineUserAvailableProducts = () => {
    const { document } = evaluationResult.evaluation

    if (!hasCompletedApplication) {
      return UserAvailableProducts({
        document,
        options: {
          application: {
            isAllowed: true,
            analyticsEvent: AnalyticsEvents.sp_approved_start_application,
          },
        },
      })
    }

    return UserAvailableProducts({
      borrowerId: evaluationResult?.applicationSetup?.borrowerId,
      document,
      options: {
        contractSimulation: {
          isAllowed: !!evaluationResult.options?.isContractSimulationAllowed,
          simulationMaxValue: totalAmount,
          simulationFlowStartedFrom: PagesPathEnum.TRANSACTIONAL_CREDIT_PAGE,
        },
        updateBorrowerData: {
          isAllowed: true,
        },
      },
    })
  }

  const userAvailableProducts = defineUserAvailableProducts()

  return (
    <TransactionalCreditResultGenericScreen
      style={{
        mainContainerPadding: '24px 24px 0px 24px',
      }}
      shouldRenderCloseButton
      icon={<ApprovedCheckIcon />}
      title={'Super Compra aprovada'}
      subtitle={subtitle}
      limits={{ title: 'Valor total disponível para comprar', totalAmount, recurrenceAvailableAmount: borrowerAvailableLimit, evaluationMaxAmount }}
      renderProgressBar
      // TODO: remove installmentsSimulation after ends the contract simulation rollout
      installmentsSimulation={!evaluationResult.options?.isContractSimulationAllowed ? evaluationResult?.simulation : undefined}
      onCloseButtonClick={handleGoBackToStart}
      userAvailableProducts={userAvailableProducts}
    />
  )
}
