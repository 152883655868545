import { SourceProduct } from '../../coordinator/dto/Contracts.dto'

export enum EvaluationResultEnum {
  APPROVED = 'APPROVED',
  PARTIALLY_APPROVED = 'PARTIALLY_APPROVED',
  DENIED = 'DENIED',
}

export enum EvaluationStatusEnum {
  CREATED = 'CREATED',
  ACCEPTED = 'ACCEPTED',
  EXPIRED = 'EXPIRED',
  APPLIED = 'APPLIED',
}

export interface TransactionalCreditEvaluation {
  id: string
  expirationTimestamp: string
  document: string
  status: EvaluationStatusEnum
  result: EvaluationResultEnum
  denialReason?: string
  parameters: TransactionalCreditParameters
  policy?: TransactionalCreditPolicy
  products?: ProductResponse[]
  sourceProduct?: SourceProduct
  options?: TransactionalCreditEvaluationOptions
}

export interface TransactionalCreditParameters {
  requestedAmount: number
  storeId?: string
}

export interface FinancingParameters {
  numberOfInstallments: number
  interestRate: number
}

export interface TransactionalCreditPolicy {
  minAmount: number
  maxAmount: number
  financingParameters?: FinancingParameters[]
}

export interface ProductOfferResponse {
  price: number
  downPayment?: number
  priceMinusDownPayment?: number
}

export interface ProductResponse {
  model: string
  manufacturer: string
  description?: string
  offers: ProductOfferResponse[]
}

export interface CreateTransactionalCreditEvaluationResponse {
  transactionalCreditEvaluation: AggregatedTransactionalCreditEvaluation
}

export interface Limit {
  totalLimit: number
  availableLimit: number
}

export interface TransactionalCreditEvaluationOptions {
  isContractSimulationAllowed?: boolean
}

export interface AggregatedTransactionalCreditEvaluation {
  evaluation: TransactionalCreditEvaluation
  simulation?: EvaluationSimulation[]
  applicationSetup?: ApplicationSetup
  limit?: Limit
  options?: TransactionalCreditEvaluationOptions
}

export interface EvaluationSimulation {
  numberOfInstallments: number
  installmentAmount: number
  principalAmount: number
  totalDebtAmount: number
}

export enum ApplicationSetupStatusEnum {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
}

export interface ApplicationSetup {
  status: ApplicationSetupStatusEnum
  limitAmount?: number
  borrowerId?: string
}

export interface CreateTransactionalCreditEvaluationReq {
  document: string
  requestedAmount: number
  storeId?: string
  operatorPhoneNumber?: string
}

export enum DeniedCreditStatus {
  PRE_APPLICATION_APPROVED_AND_EVALUATION_DENIED,
  EVALUATION_DENIED_AND_AVAILABLE_LIMIT_ABOVE_THRESHOLD,
  EVALUATION_DENIED_AND_AVAILABLE_LIMIT_BELOW_THRESHOLD,
}
